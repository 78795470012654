
import { Component, Injector, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MiscUtil } from '../../../util/misc.util';

 
import { DynformControl } from '../../../model/dymform-control';

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";
import { BtSelectGuestsComponent } from '../select-guests/select-guests.component';
import { BtEvent } from '../../../services/event.service';



import { BtCartAddItemComponent } from '../cart-add-item/cart-add-item.component';
// import { OrderFormRouteComponent } from '@app/components/widget/order-form-route/order-form-route.component';
import { BtPwPeriodGuestsComponent } from '../pw-period-guests/pw-period-guests.component';

import { BtFormRowComponent } from '../../form/bt-form-row/bt-form-row.component';
import { BtAlertComponent } from '../../ui/bt-alert/bt-alert.component';


@Component({
  selector: 'btlib-cart-add',
  standalone: true,
  imports: [ 
    LibModulesWolcModule, 
    BtFormRowComponent,
    BtCartAddItemComponent, 
    // OrderFormRouteComponent,
    BtPwPeriodGuestsComponent,
    BtAlertComponent,
   ],
  templateUrl: './cart-add.component.html',
  styleUrl: './cart-add.component.scss'
})
export class BtCartAddComponent {
  bas = inject(BooktechAppService);

  // @Input() pw:any;
  @Output() change = new EventEmitter<any>();

  
  @Input() 
  set input(input: any) {
    if(this.bas.envtest) console.log("input: ", input);
    if (input == undefined) {
      this.pw = undefined;
      return;
    }

    let pw = input.pw;
    if (!pw) {
      this.pw = undefined;
      return;
    }

    this.setPw(pw);

  }
  pw:any = undefined;
  inputValues:any = {

  }

  constructor() {


    if(this.bas.envtest) console.log("AppCartAddComponent");


  }

  ngOnInit(): void {

  }

  
  onPeriodGuestsUpdate(event:any) {
    if (!event.field) {
      return;
    }
    if(this.bas.envtest) console.log("onPeriodGuestsUpdate, event: ", event);

    this.updatePw();

  }

  async updatePw() {

    let pw = await this.bas.os.updatePw(this.pw);
    this.setPw(pw);

    this.change.emit({ field: "pw", value: pw });
  }

  setPw(pw:any) {
    pw.accessories = pw.accessories || [];
    // pw.accessories.map((acc:any) => acc.parentObj = pw );
    pw.accessoriesMandatory = pw.accessories.filter((acc:any) => acc.findMandatory);
    pw.accessoriesOptional = pw.accessories.filter((acc:any) => !acc.findMandatory);



    this.updateInputValues(pw)

    this.pw = pw;
    this.updateAmount();
  }

  onPoolUnitChange($event:Event) {
    if(this.bas.envtest) console.log("onPoolUnitChange, ev: ", $event);

    let pw = this.pw;
    pw.orgAmount = pw.amountDouble;
    
      let apu = $event && this.pw.availablePoolUnits.find((i:any) => i.id == $event);


    pw.poolUnit = apu;
    pw.amountDouble = apu ? apu.amountAsDouble : pw.orgAmount ;
    this.updateAmount();
  }

  onItemChange($event:Event, item:any) {
    // if(this.bas.envtest) console.log("onAccChange, ev: " + $event + ", acc: ", acc);
    // acc.amountDouble = acc.unitAmount * acc.quantity;

    this.updateAmount();
  }

  updateAmount() {
    let pw = this.pw;
    let amount = pw.amountDouble;
    
    for (let acc of pw.accessoriesMandatory) {
      amount += acc.amountDouble;
    }
    for (let acc of pw.accessoriesOptional) {
      amount += acc.amountDouble;
    }

    if (pw.groupItems?.length) {
      for (let gi of pw.groupItems) {
        amount += gi.amountDouble;
      }
    }
    pw.calcAmount = amount;
  }

  // submit() {
  //   this.change.emit({ 
  //     action: "submit"
  //   });
  // }

    
  drawerClose() {
    this.pw = undefined; // TODO: kan ikke åpne den ignen for set-funksjonen kalles ikke
  }

  validate() {
    let rv = this.inputValues.obj ? this.bas.fs.getRawValue(this.inputValues.form, this.inputValues.controls) : true;

    if (rv === false) return false;

    this.pw.jsonData.inputValuesMap = rv;

    return true;
  }

  addToCart() {
    if (!this.validate()) return;
    this.bas.es.trigger(BtEvent.LibCartAdd, { pw: this.pw });
    this.drawerClose();
  }


  checkout() {
    if (!this.validate()) return;
    this.bas.es.trigger(BtEvent.LibCartAdd, { pw: this.pw });

    this.bas.ui.router.navigate([ 
      this.bas.ui.getRouterPrefix() 
        + '/checkout'
      ], { queryParams: { 
        // id: "test" 
      }}
    );
  }


  updateInputValues(pw:any){
    let controls: DynformControl[] = [];

    // let inputValuesGuestMap = new DynformControl({ 
    //   key: 'inputValuesGuestMap_',
    //   controlType: 'formGroup',
    //   children: [ ]
    // })
    // controls.push(inputValuesGuestMap);


    if(pw.inputValuesList) {
      let rv = this.inputValues.obj ? this.bas.fs.getRawValue(this.inputValues.form, this.inputValues.controls, true) : { };

      for (let iv of pw.inputValuesList || []) {

        // let ct = "input";


        let io = iv.inputObj;
        let type = io.typeEnum;
        let isGuestInput = io.guestInput;

        let mk = io.mkName;

        if (isGuestInput) mk = "#" + iv.mkIndex + ": " + iv.mkGc + ": " + mk;

        let ctl = new DynformControl({  
          controlType: "input",
          key: iv.extKey, 
          mk: mk,
          required: io.required,
          value: rv[iv.extKey] || iv.value || io.mkDefaultValue,
          data: {
            isGuestInput: isGuestInput
          }
        });
        
        switch (type.enumName) {
          case "Checkbox": 
            ctl.controlType = "checkbox"; 
            ctl.value = io.mkDefaultValue === "true" ? true : false;
            break;
          case "TextArea": ctl.controlType = "textarea"; break;
          case "Select": 
            ctl.controlType =  "select"; 
            ctl.optionsList = (io.optionsAsList || []).map((item:string) => { return  { label: item, value: item } });
            ctl.optionsAllowEmpty = true;
            break;
        }
 
        controls.push(ctl);
      }
      /*

      */
    }

    
    this.inputValues.list = controls;
    this.bas.fs.updateFormObj(this.inputValues, controls, { });
  }


}
