
export const environment = {
  production: false,
  
  settings: {
    env: false,
    appType: "btng",
    appId: "cbwidget",
    cid: "_",
    name: "Cloud Booking Widget API",
    langs: [ "no", "en" ], // , "en"
    pushTopics: [ ],
    standalone: true,
  }
  
};
